import { Outlet } from "react-router-dom";

import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import {
  AssignmentCreate,
  AssignmentGenerateTalentDescription,
  AssignmentManagement,
  Assignments,
  AssignmentTimeline,
  CreateAssignment,
  AssignmentTalentContractInformation,
} from "routes/pages.import";

export const assignment = [
  {
    path: "/assignments",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Assignments />
          </ProtectedRoute>
        ),
      },
      {
        path: "create",
        element: (
          <ProtectedRoute>
            <AssignmentCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: "create-v2",
        element: <CreateAssignment />,
      },
      {
        path: "manage/:id",
        element: (
          <ProtectedRoute layoutType="ASSIGNMENT_MANAGEMENT">
            <AssignmentManagement />
          </ProtectedRoute>
        ),
      },
      {
        path: "generate-job-description/:id",
        element: (
          <ProtectedRoute>
            <AssignmentGenerateTalentDescription />
          </ProtectedRoute>
        ),
      },
      {
        path: "generate-job-description/:ats_base/:ats_assignment_id",
        element: (
          <ProtectedRoute>
            <AssignmentGenerateTalentDescription />
          </ProtectedRoute>
        ),
      },
      {
        path: "timelines",
        element: (
          <ProtectedRoute>
            <AssignmentTimeline />
          </ProtectedRoute>
        ),
      },
      {
        path: "contract-info/:assignmentId/talent/:talentId",
        element: <AssignmentTalentContractInformation />,
      },
    ],
  },
];
