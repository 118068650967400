/**
 *  @namespace environment
 */

import { getSessionStorage } from "utils/helpers/session";

/**
 * Base URL for API endpoints.
 *
 * This constant is initialized with the value from the environment variable REACT_APP_API_BASE,
 * parsed from JSON. If the environment variable is not defined or cannot be parsed as JSON,
 * an empty array `[]` is used as the default value.
 * @type {Array}
 * @memberof environment
 * @example
 * // Example usage
 * console.log(API_BASE);
 * // Output example: ["https://api.example.com"]
 */
export const API_BASE = JSON.parse(process.env.REACT_APP_API_BASE || "[]");

/**
 * Enum representing different stages or environments.
 *
 * This constant defines key stages or environments used in the application:
 * - DEV: Development environment
 * - PROD: Production environment
 * - LOCAL: Local development environment
 * - PRE_PROD: Pre-production or staging environment
 *
 * @enum {string}
 * @memberof environment
 *
 */
export const STAGES = {
  DEV: "DEV",
  PROD: "PROD",
  LOCAL: "LOCAL",
  PRE_PROD: "PRE_PROD",
};

/**
 * Configuration object containing various application settings.
 *
 * @typedef {Object} AppConfig
 * @property {string} token - Token retrieved from sessionStorage.
 * @property {string} baseUrl - Base URL for API endpoints, retrieved from environment variable REACT_APP_API_URL.
 * @property {string} mediaUrl - Base URL for media resources, retrieved from environment variable REACT_APP_MEDIA_URL.
 */

/**
 * Configuration object containing various application settings.
 *
 * @type {AppConfig}
 * @property {string} token - Token retrieved from sessionStorage.
 * @property {string} baseUrl - Base URL for API endpoints, retrieved from environment variable REACT_APP_API_URL.
 * @property {string} mediaUrl - Base URL for media resources, retrieved from environment variable REACT_APP_MEDIA_URL.
 *
 * @memberof environment
 */
export const CONFIG = {
  token: getSessionStorage().token,
  baseUrl: process.env.REACT_APP_API_URL || "",
  mediaUrl: process.env.REACT_APP_MEDIA_URL || "",
};
