import Clarity from "@microsoft/clarity";
import { useEffect } from "react";

export function useClarityTracking(isAuthenticated, sessionCtx) {
  useEffect(() => {
    if (!isAuthenticated || !window?.clarity) return;

    const { user } = sessionCtx ?? {};

    if (!user?.email) return;

    const fullName = `${user.firstname ?? ""} ${user.lastname ?? ""}`.trim();

    const [splittedEmail = ""] = user.email?.split("@") ?? [];
    const identifier = splittedEmail.toLowerCase();

    if (identifier) {
      Clarity.identify(identifier, identifier, "", fullName);
    }
  }, [isAuthenticated, sessionCtx]);
}
