import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";

import { Home, NotFound } from "./pages.import";
import { assignment } from "./assignment";
import { login } from "./login";
import { matche } from "./matche";
import { statisticFollowUp } from "./followUp";
import { directory } from "./directory";
import { kpi } from "./kpi";
import { document } from "./document";
import { company } from "./company";
import { talent } from "./talent";
import { notification } from "./notification";
import { user } from "./user";
import { invoicing } from "./invoicing";
import { cra } from "./cra";

export const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  ...login,
  ...assignment,
  ...matche,
  ...statisticFollowUp,
  ...directory,
  ...kpi,
  ...document,
  ...company,
  ...talent,
  ...notification,
  ...user,
  ...invoicing,
  ...cra,
  {
    path: "*",
    element: <NotFound />,
  },
];
