/* eslint-disable */
import React, { useState, useEffect, useCallback, useContext } from "react";
// Simulation du géstionnaire de state de ExtJS
import ExtjsStateManager from "../utils/extjs-state-manager";

import * as SentryReact from "@sentry/react";

import { clearFollowUpLocalStorage } from "components/StatisticFollowUp/utils/utils";

import {
  getApiUrl as getApiUrlFromBase,
  getMediaUrl as getMediaUrlFromBase,
} from "utils/helpers/configuration";
import { getDefaultApiBase } from "utils/helpers/configuration";

const defaultSessionData = {
  tokenApi: null,
  tokenMedia: null,
  user: null,
  connect: null,
  config: null,
  rights: null,
  ats: null,
  timezoneServer: "+01:00",
  googleApiKey: null,
  apiBase: null,
};

const SessionContext = React.createContext({
  ...defaultSessionData,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  handleUpdateUser: () => {},
  handleUpdateApiBase: () => {},
  getApiUrl: () => {},
  getMediaUrl: () => {},
});

const retrieveStoredSession = () => {
  //TODO: à remplacer lorsque la compatibilité avec le gestionnaire de session de ExtJS ne sera plus nécéssaire
  const storedSession = ExtjsStateManager.get("ext-session");

  if (storedSession) {
    if (Date.now() < storedSession.tokenApi.expirationDate.getTime())
      return storedSession;
    // Le token est expiré
    ExtjsStateManager.clear("ext-session");
  }

  return defaultSessionData;
};

export const SessionContextProvider = ({ children }) => {
  const storedSession = retrieveStoredSession();
  const [apiBase, setApiBase] = useState(getDefaultApiBase());
  const [sessionData, setSessionData] = useState(storedSession);

  const handleUpdateApiBase = (apiBase) => {
    if (!apiBase) {
      ExtjsStateManager.clear("ext-api-base");
    }

    ExtjsStateManager.set("ext-api-base", apiBase);
    setApiBase(apiBase);
  };

  /**
   * getApiUrl
   *
   * You can use this function to retrieve the API base URL.
   * The URL obtained depends on the selected country (Europe or Asia).
   *
   * - Use this function inside components or custom hooks.
   * - If you need to call this function inside a utility function,
   *   please import and use `getApiUrl` from `utils/helpers/configuration.js`.
   *
   * @returns string
   */
  const getApiUrl = useCallback(() => getApiUrlFromBase(apiBase), [apiBase]);

  /**
   * getMediaUrl
   *
   * Get Media base URL depends on selected country (Europe or Asia)
   * @returns string
   */
  const getMediaUrl = useCallback(
    () => getMediaUrlFromBase(apiBase),
    [apiBase],
  );

  const logoutHandler = useCallback(() => {
    //TODO: à remplacer lorsque la compatibilité avec le gestionnaire de session de ExtJS ne sera plus nécéssaire
    ExtjsStateManager.clear("ext-session");

    setSessionData({ ...defaultSessionData });
    clearFollowUpLocalStorage();
  }, []);

  const loginHandler = ({
    token_api,
    token_media,
    rights,
    config,
    connect,
    expiration,
    api_google_key,
    ats,
    messaging,
    user_id,
  }) => {
    const user = token_api.user;

    const newSessionData = {
      tokenApi: {
        token: token_api.token,
        expirationDate: new Date(token_api.expiration_date),
      },
      tokenMedia: {
        token: token_media.token,
        expirationDate: new Date(token_media.expiration_date),
      },
      user: { ...user },
      connect: { ...connect },
      rights,
      config,
      ats,
      timezoneServer: expiration.timezone,
      googleApiKey: api_google_key,
      messaging,
      user_id,
    };
    //TODO: à remplacer lorsque la compatibilité avec le gestionnaire de session de ExtJS ne sera plus nécéssaire
    ExtjsStateManager.set("ext-session", newSessionData);

    SentryReact.setUser({
      id: user.id,
      email: user.email,
      name: `${user.firstname} ${user.lastname}`,
    });

    setSessionData(newSessionData);
  };

  useEffect(() => {
    if (!sessionData?.tokenApi || !apiBase) return;

    async function apiTestToken(token, apiBase) {
      try {
        const response = await fetch(
          `${getApiUrl(apiBase)}token_status/${token}`,
          {
            headers: {
              Accept: "application/json",
            },
            mode: "cors",
          },
        );
        const responseJson = await response.json();
        if (
          !responseJson ||
          !responseJson.success ||
          !responseJson.token.is_valid
        ) {
          // Le token n'est plus valide
          //TODO: à remplacer lorsque la compatibilité avec le gestionnaire de session de ExtJS ne sera plus nécéssaire
          ExtjsStateManager.clear("ext-session");

          setSessionData({ ...defaultSessionData });
        }
      } catch (e) {
        console.log(e);
      }
    }

    apiTestToken(sessionData.tokenApi.token, apiBase);
  }, [sessionData.tokenApi, apiBase]);

  const handleUpdateUser = ({ picture_path }) => {
    setSessionData((prev) => {
      const newDataSession = {
        ...prev,
        user: {
          ...prev.user,
          picture_path,
        },
      };
      ExtjsStateManager.set("ext-session", newDataSession);
      return newDataSession;
    });
  };

  const contextValue = {
    ...sessionData,
    isLoggedIn: !!sessionData.tokenApi,
    login: loginHandler,
    logout: logoutHandler,
    handleUpdateUser: handleUpdateUser,
    handleUpdateApiBase,
    getApiUrl,
    getMediaUrl,
    apiBase,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;

export const useSessionContext = () => useContext(SessionContext);
