import dayjs from "dayjs";

export const currentYear = dayjs().year();
export const previousYear = currentYear - 1;

export const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const alphabeticalMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonths = (start = 0, month) =>
  [...months].slice(start, months.indexOf(month) + 1);

export const getPreviousMonths = (from, to) => {
  if (to.year === currentYear && from.year === currentYear) {
    return undefined;
  }
  if (from.year === previousYear && to.year === previousYear) {
    return getMonths(from.month, to.month);
  }
  return getMonths(from.month, 11);
};

export const getCurrentMonths = (from, to) => {
  if (from.year === previousYear && to.year === previousYear) {
    return undefined;
  }
  if (to.year === currentYear && from.year === currentYear) {
    return getMonths(from.month, to.month);
  }
  return getMonths(0, to.month);
};

export const getStatistics = (
  previousYearStatistics = 0,
  currentYearStatistics = 0,
  turnover = 1,
) => {
  const value =
    previousYearStatistics && currentYearStatistics
      ? previousYearStatistics + currentYearStatistics
      : previousYearStatistics || currentYearStatistics;

  const percentage = value > 0 ? (value * 100) / turnover : 0;

  return {
    value,
    percentage,
  };
};

export const getStatisticByYearType = (
  type,
  selectedMonths = [],
  statisticData,
) =>
  selectedMonths
    ? selectedMonths.reduce((acc, current) => {
        const currentMonth = alphabeticalMonths[current];
        const currentMonthStatistic = statisticData.find(
          (statistic) => statistic.month === currentMonth,
        );
        return currentMonthStatistic
          ? currentMonthStatistic[`${type}`] + acc
          : acc;
      }, 0)
    : 0;

export const getDateDifference = (from, to) => {
  let currentYearDateDifference = 0;
  let previousYearDateDifference = 0;
  if (currentYear === from.year()) {
    currentYearDateDifference = to.diff(from, "days");
  } else {
    previousYearDateDifference = dayjs(new Date(previousYear, 11, 31)).diff(
      from,
      "days",
    );
    currentYearDateDifference = to.diff(new Date(currentYear, 0, 1), "days");
  }
  return { previousYearDateDifference, currentYearDateDifference };
};

export const calculateBudget = ({
  entireCurrentBudgetYear = 0,
  entirePreviousBudgetYear = 0,
  from,
  to,
}) => {
  const { currentYearDateDifference, previousYearDateDifference } =
    getDateDifference(from, to);

  const filterOverlap =
    previousYearDateDifference > 0 && currentYearDateDifference > 0;

  if (filterOverlap) return undefined;

  return previousYearDateDifference > 0
    ? (entirePreviousBudgetYear / 365) * previousYearDateDifference
    : (entireCurrentBudgetYear / 365) * currentYearDateDifference;
};

export const calculateBudgetCAPercentage = (budget, ca) => {
  if (budget === 0) return 0;
  return (ca * 100) / budget;
};

export const formatToLocaleCurrency = (value) => {
  const fixed = value.toFixed(2).split(".");
  return `${Number(fixed[0]).toLocaleString("en").replaceAll(",", "  ")}.${
    fixed[1]
  }`;
};

export const calculateCa = ({ from, to, data = [] }) => {
  const fromValue = {
    month: from.month(),
    year: from.year(),
  };
  const toValue = {
    month: to.month(),
    year: to.year(),
  };

  const selectedDate = {
    previousYear: {
      selectedMonths: getPreviousMonths(fromValue, toValue),
    },
    currentYear: {
      selectedMonths: getCurrentMonths(fromValue, toValue),
    },
  };
  const currentYearStatistic = getStatisticByYearType(
    "y",
    selectedDate.currentYear.selectedMonths,
    data,
  );

  const previousYearStatistic = getStatisticByYearType(
    "y-1",
    selectedDate.previousYear.selectedMonths,
    data,
  );

  return currentYearStatistic + previousYearStatistic;
};

export const getCurrentYearDates = () => {
  const value = {
    from: {
      month: 0,
      year: new Date().getFullYear(),
    },
    to: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
  };

  return {
    previousYear: {
      selectedMonths: getPreviousMonths(value.from, value.to),
    },
    currentYear: {
      selectedMonths: getCurrentMonths(value.from, value.to),
    },
  };
};

export const parseFollowUpStatisticsData = (resp, selectedDate) => {
  if (resp.error) return;

  const netStatisticData = resp.billing?.net;
  const grossStatisticData = resp.billing?.raw;

  const selectedPreviousYear = !!selectedDate.previousYear.selectedMonths;
  const selectedCurrentYear = !!selectedDate.currentYear.selectedMonths;
  // const filterOverlap = selectedPreviousYear && selectedCurrentYear;

  let previousYearBilling = {
    ca: {
      gross: 0,
      net: 0,
    },
    budget: {
      gross: 0,
      net: 0,
    },
  };

  let currentYearBilling = {
    ca: {
      gross: 0,
      net: 0,
    },
    budget: {
      gross: 0,
      net: 0,
    },
  };

  if (selectedPreviousYear) {
    previousYearBilling = {
      ca: {
        gross: resp?.billing?.["y-1"]?.ca?.raw,
        net: resp?.billing?.["y-1"]?.ca?.net,
      },
      budget: {
        gross: resp?.billing?.["y-1"]?.budget?.raw,
        net: resp?.billing?.["y-1"]?.budget?.net,
      },
    };
  }

  if (selectedCurrentYear) {
    currentYearBilling = {
      ca: {
        gross: resp?.billing?.["y"]?.ca?.raw,
        net: resp?.billing?.["y"]?.ca?.net,
      },
      budget: {
        gross: resp?.billing?.["y"]?.budget?.raw,
        net: resp?.billing?.["y"]?.budget?.net,
      },
    };
  }

  const netCA = currentYearBilling.ca.net + previousYearBilling.ca.net;
  const grossCA = currentYearBilling.ca.gross + previousYearBilling.ca.gross;

  const netBudget =
    currentYearBilling.budget.net + previousYearBilling.budget.net;
  const grossBudget =
    currentYearBilling.budget.gross + previousYearBilling.budget.gross;

  const netCurrentYearStatistic = getStatisticByYearType(
    "y",
    selectedDate.currentYear.selectedMonths,
    netStatisticData,
  );
  const netPreviousYearStatistic = getStatisticByYearType(
    "y-1",
    selectedDate.previousYear.selectedMonths,
    netStatisticData,
  );

  const grossCurrentYearStatistic = getStatisticByYearType(
    "y",
    selectedDate.currentYear.selectedMonths,
    grossStatisticData,
  );
  const grossPreviousYearStatistic = getStatisticByYearType(
    "y-1",
    selectedDate.previousYear.selectedMonths,
    grossStatisticData,
  );

  const currentTeamStats = {
    gross: grossStatisticData,
    net: netStatisticData,
    ca: {
      raw: grossCA,
      net: netCA,
    },
    budget: {
      raw: grossBudget,
      net: netBudget,
    },
  };

  const turnoverStats = {
    gross: {
      ca: getStatistics(
        grossPreviousYearStatistic,
        grossCurrentYearStatistic,
        grossCA,
      ),
      budget: grossBudget || 0,
    },
    net: {
      ca: getStatistics(
        netPreviousYearStatistic,
        netCurrentYearStatistic,
        netCA,
      ),
      budget: netBudget || 0,
    },
  };

  return {
    turnoverStats,
    currentTeamStats,
  };
};

export const combineStats = (allStats) =>
  allStats.reduce((acc, curr) => {
    const currentTeamStats = {
      gross: "g"
        .repeat(12)
        .split("")
        .map((m, idx) => ({
          month: acc.currentTeamStats.gross[idx].month,
          y:
            acc.currentTeamStats.gross[idx]["y"] +
            curr.currentTeamStats.gross[idx]["y"],
          "y-1":
            acc.currentTeamStats.gross[idx]["y-1"] +
            curr.currentTeamStats.gross[idx]["y-1"],
        })), // []
      net: "n"
        .repeat(12)
        .split("")
        .map((m, idx) => ({
          month: acc.currentTeamStats.net[idx].month,
          y:
            acc.currentTeamStats.net[idx]["y"] +
            curr.currentTeamStats.net[idx]["y"],
          "y-1":
            acc.currentTeamStats.net[idx]["y-1"] +
            curr.currentTeamStats.net[idx]["y-1"],
        })), // []
      budget: {
        raw: acc.currentTeamStats.budget.raw + curr.currentTeamStats.budget.raw,
        net: acc.currentTeamStats.budget.net + curr.currentTeamStats.budget.net,
      },
      ca: {
        raw: acc.currentTeamStats.ca.raw + curr.currentTeamStats.ca.raw,
        net: acc.currentTeamStats.ca.net + curr.currentTeamStats.ca.net,
      },
    };

    const turnoverStats = {
      gross: {
        ca: {
          value:
            acc.turnoverStats.gross.ca.value +
            curr.turnoverStats.gross.ca.value,
          // percentage: 45,
        }, // { value, percentage }
        budget:
          acc.turnoverStats.gross.budget + curr.turnoverStats.gross.budget,
      },
      net: {
        ca: {
          value:
            acc.turnoverStats.net.ca.value + curr.turnoverStats.net.ca.value,
          // percentage: 45,
        }, // { value, percentage }
        budget: acc.turnoverStats.net.budget + curr.turnoverStats.net.budget,
      },
    };
    return { currentTeamStats, turnoverStats };
  });

const empty = (obj) => obj == null || obj === "" || typeof obj === "undefined";

export const getFromLocalStorage = (key) => {
  const keyLS = localStorage.getItem(key);
  if (!empty(keyLS) && keyLS && keyLS !== "undefined") return JSON.parse(keyLS);
  return null;
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const removeFromLocalStorage = (key) => localStorage.removeItem(key);

export const clearFollowUpLocalStorage = () => {
  const followUpSavedValues = [
    "selectedDates",
    "filterDates",
    "teamTurnoverStats",
    "consultantTurnoverStats",
    "teamId",
    "breadcrumb",
  ];

  followUpSavedValues.forEach((e) => {
    localStorage.removeItem(e);
  });
};
