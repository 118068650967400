/** * @module date */

import dayjs from "dayjs";
import moment from "moment";

/**
 * Converts a date object or timestamp into an object containing formatted date and time strings.
 *
 * @param {string|number|Date} [date=new Date()] - The date object or timestamp to convert. Defaults to the current date and time.
 * @returns {Object} An object containing formatted date and time strings.
 * @returns {string} return.formattedDate - The formatted date string in "YYYY-MM-DD" format.
 * @returns {string} return.formattedHour - The formatted time string in "HH:mm:ss" format.
 *
 * @example
 * const datetimeObject = getDatetimeToObject('2023-06-01');
 * console.log(datetimeObject);
 * // Output: { formattedDate: '2023-06-01', formattedHour: '00:00:00' }
 *
 * const currentDatetimeObject = getDatetimeToObject();
 * console.log(currentDatetimeObject);
 * // Output: { formattedDate: '2023-06-01', formattedHour: '12:34:56' } // assuming current date and time
 */
export function getDatetimeToObject(date = new Date()) {
  const convertedDate = dayjs(date);
  const formattedDate = convertedDate.format("YYYY-MM-DD");
  const formattedHour = convertedDate.format("HH:mm:ss");
  return {
    formattedDate,
    formattedHour,
  };
}

/**
 * Formats a given date into "DD/MM/YYYY" format.
 *
 * @param {string|Date} [date] - The date to format. If not provided, an empty string will be returned.
 * @returns {string} The formatted date string in "DD/MM/YYYY" format, or an empty string if the date is not provided.
 *
 * @example
 * const formattedDate = convertDateIntoDMY('2023-06-01');
 * console.log(formattedDate); // Output: '01/06/2023'
 *
 * const invalidDate = convertDateIntoDMY(null);
 * console.log(invalidDate); // Output: ''
 *
 */
export function convertDateIntoDMY(date) {
  return date ? dayjs(date).format("DD/MM/YYYY") : "";
}

/**
 * Formats a date into a specified format using dayjs.
 *
 * @param {string|number|Date} date - The date to format. Can be a string, number (timestamp), or Date object.
 * @param {string} [format="DD/MM/YYYY"] - The format string to apply. Defaults to "DD/MM/YYYY".
 * @returns {string} The formatted date string according to the specified format.
 *
 * @example
 * const formattedDate = formatDate('2023-06-01');
 * console.log(formattedDate); // Output: '01/06/2023'
 *
 * const customFormat = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss');
 * console.log(customFormat); // Output: '2023-06-01 12:34:56'
 */
export function formatDate(date, format = "DD/MM/YYYY") {
  return dayjs(new Date(date)).format(format);
}

/**
 * Formats the given date object or the current date and time to a string representation in the format "YYYY-MM-DD HH:mm:ss".
 *
 * @param {Date} [date=days()] - The date object to format. Defaults to the current date and time if not provided.
 * @returns {string} A string representation of the date and time in the format "YYYY-MM-DD HH:mm:ss".
 *
 * @example
 * const datetimeString = getDateTimeToString('2023-06-01T12:00:00');
 * console.log(datetimeString); // Output: '2023-06-01 12:00:00'
 *
 * const currentDatetime = getDateTimeToString();
 * console.log(currentDatetime); // Output: Current date and time formatted as 'YYYY-MM-DD HH:mm:ss'
 */
export function getDateTimeToString(date = dayjs()) {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

/**
 * Extracts the date and time parts from a given date object.
 *
 * @param {Date} [date=new Date()] - The date object to extract parts from.
 * @typedef {Object} DateAndTime - Returned date format
 * @property {string} date - The formatted date part in "YYYY-MM-DD" format.
 * @property {string} time - The formatted time part in "HH:mm:ss" format.
 * @returns {DateAndTime} - An object containing the formatted date and time parts.
 *
 * @example
 * const dateParts = getDateAndTimeParts('2023-06-01T12:34:56');
 * console.log(dateParts);
 * // Output: { date: '2023-06-01', time: '12:34:56' }
 *
 * const currentDateTimeParts = getDateAndTimeParts();
 * console.log(currentDateTimeParts);
 * // Output: Current date and time parts formatted as { date: 'YYYY-MM-DD', time: 'HH:mm:ss' }
 */
export function getDateAndTimeParts(date = new Date()) {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  const formattedTime = dayjs(date).format("HH:mm:ss");

  return { date: formattedDate, time: formattedTime };
}

/**
 * Calculates the duration between two dates in terms of months or days.
 *
 * @param {string} startDate - The start date in a format parseable by dayjs.
 * @param {string} endDate - The end date in a format parseable by dayjs.
 * @returns {string} - The duration between the dates in the format "x day(s)" or "x month(s) and y day(s)".
 *
 * @example
 * const duration = getDuration('2023-06-01', '2023-08-15');
 * console.log(duration); // Output: '2 months and 14 days'
 *
 * const sameDay = getDuration('2023-06-01', '2023-06-01');
 * console.log(sameDay); // Output: ''
 *
 */
export function getDuration(startDate, endDate) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diffInDays = end.diff(start, "day");
  const diffInMonths = end.diff(start, "month");

  // Calculate the remaining days after accounting for full months
  const remainingDays = end.subtract(diffInMonths, "month").diff(start, "day");

  if (!diffInDays) return "";

  if (diffInMonths < 1) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""}`;
  }
  if (remainingDays === 0) {
    return `${diffInMonths} month${diffInMonths !== 1 ? "s" : ""}`;
  }
  return `${diffInMonths} month${
    diffInMonths !== 1 ? "s" : ""
  } and ${remainingDays} day${remainingDays !== 1 ? "s" : ""}`;
}

/**
 * Formats a given date and hour into a single string.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string|Date} params.date - The date to be formatted.
 * @param {string|Date} params.hour - The hour to be formatted.
 * @returns {string} The formatted date and hour in the format "YYYY-MM-DD HH:mm:ss".
 */
export function formatDateTime({ date, hour }) {
  return `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(hour).format(
    "HH:mm:ss",
  )}`;
}

/**
 * Converts a time string (formatted as "HH:mm:ss") into a dayjs object with the corresponding time.
 *
 * @param {string} [value=""] - The time string to convert, formatted as "HH:mm:ss".
 * @returns {Object} A dayjs object with the time set to the parsed hour, minute, and second.
 *
 * @example
 * const timeObj = parseTimeStringToDayjs('12:34:56');
 * console.log(timeObj.format('HH:mm:ss')); // Output: '12:34:56'
 *
 * const noTime = parseTimeStringToDayjs();
 * console.log(noTime); // Output: Current time as a dayjs object
 */
export function parseTimeStringToDayjs(value = "") {
  const splittedValue = value.split(":");
  if (splittedValue.length === 0) return value;
  const [hour, minute, second] = splittedValue;

  return dayjs()
    .hour(parseInt(hour))
    .minute(parseInt(minute))
    .second(parseInt(second));
}

/**
 * Parses a date string into a dayjs object.
 *
 * @param {string} [data] - The date string to parse. If not provided, the current date and time will be used.
 * @returns {Object} An object containing the parsed date as a dayjs object.
 * @returns {Object} return.date - The parsed date as a dayjs object.
 *
 * @example
 * const dateObj = parseDateToDayjsObject('2023-06-01');
 * console.log(dateObj.date.format('YYYY-MM-DD')); // Output: '2023-06-01'
 *
 * const currentDate = parseDateToDayjsObject();
 * console.log(currentDate.date.format('YYYY-MM-DD')); // Output: Current date formatted as 'YYYY-MM-DD'
 */
export function parseDateToDayjsObject(value) {
  const date = value ? dayjs(value) : dayjs();
  return {
    date,
  };
}

/**
 * Calculates the difference in years, months, and days between two dates.
 *
 * @param {Date|string|number} beginDate - The beginning date. If not provided, defaults to the current date.
 * @param {Date|string|number} endDate - The ending date. If not provided, defaults to the current date.
 * @returns {Object} An object containing the difference in years, months, and days.
 * @returns {number} return.year - The difference in years between endDate and beginDate.
 * @returns {number} return.month - The difference in months between endDate and beginDate.
 * @returns {number} return.day - The difference in days between endDate and beginDate.
 *
 * @example
 * const dateDiff = getDateDiff('2023-01-01', '2024-03-15');
 * console.log(dateDiff);
 * // Output: { year: 1, month: 2, day: 14 }
 *
 * const currentDiff = getDateDiff();
 * console.log(currentDiff);
 * // Output: Current date difference as { year: 0, month: 0, day: 0 }
 */
export function getDateDiff(beginDate, endDate) {
  const begin = beginDate ? moment(beginDate) : moment();
  const end = endDate ? moment(endDate) : moment();
  const diffDuration = moment.duration(end.diff(begin));
  return {
    year: diffDuration.years(),
    month: diffDuration.months(),
    day: diffDuration.days(),
  };
}

/**
 * Returns the last date of the month for a given date in the format YYYY-MM-DD.
 *
 * @param {string} date - The input date in the format YYYY-MM-DD.
 * @returns {string} The last date of the month in the format YYYY-MM-DD.
 */
export function getLastDateOfMonth(date) {
  return dayjs(date).endOf("month").format("YYYY-MM-DD");
}

/**
 * Sorts an array of objects by a specified date key and sorting mode.
 *
 * @param {Object} params - The parameters for sorting.
 * @param {Array} params.data - The array of objects to sort.
 * @param {string} params.sortKeyName - The key name of the date to sort by.
 * @param {string} params.sortingMode - The sorting mode, either 'asc' or 'desc'.
 *
 * @returns {Array} - The sorted array of objects.
 */
export function sortDataByDateAndKey({
  data,
  sortKeyName,
  sortingMode = "asc",
}) {
  return (data || []).sort((a, b) => {
    const dateA = dayjs(a[sortKeyName]).format().valueOf();
    const dateB = dayjs(b[sortKeyName]).format().valueOf();

    return sortingMode === "asc" ? dateB - dateA : dateA - dateB;
  });
}

/**
 * Checks if the given date is valid
 *
 * @param {string|Date|dayjs.Dayjs} date - The date to validate. Can be a string, JavaScript Date object, Unix timestamp, or a dayjs object.
 *
 * @returns {boolean} Returns true if the date is valid, otherwise false.
 *
 */
export function isDateValid(input) {
  if (input === undefined || input === null) return false;
  return dayjs(input).isValid();
}
