import ReactGA from "react-ga4";
import { useEffect } from "react";

import { getStage } from "utils/helpers/configuration";
import { STAGES } from "utils/constants/environment/config";

const TRACKING_ID =
  process.env?.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "G-YWX5P3KJJH";

export function useGoogleAnalytics(isAuthenticated, sessionCtx) {
  const stage = getStage();

  useEffect(() => {
    if (!isAuthenticated || stage === STAGES.LOCAL) return;

    const { user } = sessionCtx ?? {};

    if (!user?.email) return;

    const [splittedEmail = ""] = user.email?.split("@") ?? [];
    const identifier = splittedEmail.toLowerCase();

    ReactGA.initialize(TRACKING_ID);

    ReactGA.set({ user_id: 1234 });
    ReactGA.event({ label: identifier, action: identifier });
  }, [isAuthenticated, sessionCtx]);
}
